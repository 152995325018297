@import 'font-awesome';

:root {
	--main-light-blue-color: #f0f7ff;
	--main-blue-color: #23abe2;
	--main-white-color: #fff;
	--main-green-color: #8ec73f;
	--main-purple-color: #3f48cc;
	--light-green-color: #a5d662;
	--main-border-color: #cdd9e6;
	--grid-border-color: #ebf1f6;
	--main-black-color: #253243;
	--main-grey-color: #70818f;
	--main-grey-bg: #70828e;
	--light-grey-color: #97a3ad;
	--main-bg-color: #f4f5f9;
	--box-border-color: #e6ecf2;
	--header-height: 57px;
	--footer-height: 47px;
	--left-panel-width: 60px;
	--side-menu-width: 250px;
	--content-padding: 30px;
	--side-menu-shadow: 3px 0px 20px rgba(0, 0, 0, 0.1);
	--light-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.05);
	--dirty-field-color: #faf0a5;
	--error-field-color: red;
}

.blue-hover:hover {
	color: #729ae4;
}

.pointer {
	cursor: pointer;
}

.inner-group-text.input-group>.input-group-prepend>.input-group-text {
	border-right: none;
}

.p-component-overlay.p-sidebar-mask {
	display: none;
}

.main-blue-bg {
	background-color: var(--main-blue-color) !important;
}

/* .login-bg {
	background: url(/images/login-bg-3.jpg) top/10% auto;
	height: 100vh;
	background-size: cover;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
} */
html,
body {
	height: 100%;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	text-align: left;
	background-color: #fff;
}

*:focus {
	box-shadow: none !important;
}

.body {
	background-color: var(--main-bg-color);
}

.dropdown-menu {
	background-color: var(--main-bg-color);
}

.logo {
	background: rgba(0, 0, 0, 0) url('/sctportal/images/logo-v1.png') no-repeat left center/65% auto;
	height: 2.5rem;
	display: block;
	width: 200px;
	cursor: pointer;
}

.sideMenuContainer .sidebarMenu .p-panelmenu-header.expanded-item-not-active {
	background: var(--main-light-blue-color) none repeat scroll 0 0 !important;
}


.width-fit-content {
	width: fit-content;
}

.red-bg {
	background: #ffcccb !important;
}

.green-bg {
	background: #d2ffcb !important;
}

.orange-bg {
	background: #ffe6cb !important;
}

.main-blue-border {
	border: 1px solid var(--main-blue-color) !important;
}

.mapboxgl-map {
	border-radius: 0.5rem !important;
}

.mapboxgl-marker {
	cursor: pointer !important;
}

.mapboxgl-popup-content {
	padding: 0.7rem;
	border-radius: 0.5rem !important;
	box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

@media (max-width:767px) {
	.mapbox-widget {
		margin-left: -1.5rem;
		margin-right: -1.5rem;
	}
}

.light-grey-border {
	border: 1px solid var(--main-border-color);
}

.main-light-blue-bg {
	background-color: var(--main-light-blue-color) !important;
}

.main-light-blue {
	color: var(--main-light-blue-color) !important;
}

.main-blue-bg {
	background-color: var(--main-blue-color) !important;
}

.main-blue-border {
	border: 1px solid var(--main-blue-color) !important;
}

.main-black {
	color: var(--main-black-color) !important;
}

.main-green {
	color: var(--main-green-color) !important;
}

.main-green-bg {
	background-color: var(--main-green-color) !important;
}

.light-green {
	color: var(--light-green-color) !important;
}

.light-green-bg {
	background-color: var(--light-green-color) !important;
}

.main-border-color {
	color: var(--main-border-color) !important;
}

.light-grey {
	color: var(--light-grey-color) !important;
}

.main-black {
	color: var(--main-black-color) !important;
}

.main-grey {
	color: var(--main-grey-color) !important;
}

.main-grey-bg {
	background-color: var(--main-grey-bg) !important;
}

.main-green-bg {
	background-color: var(--light-green-color) !important;
}

.main-bg {
	background-color: var(--main-bg-color) !important;
}

.main-white {
	color: var(--main-white-color) !important;
}

.main-blue {
	color: var(--main-blue-color) !important;
}

.main-purple {
	color: var(--main-purple-color) !important;
}

.page {
	height: 100%;
	display: flex;
	flex-flow: column;
	overflow: hidden;
	min-height: 500px;
}

.header-page {
	flex: 0 1 auto;
}

.body-page {
	flex: 1 1 auto;
	overflow: auto;
}

.footer-page {
	flex: 0 1 auto;
}

.p-button {
	white-space: nowrap;
}

/*.tabs {
	height: 50px;
}*/

.full-height-without-tabs {
	height: calc(100% - 50px) !important;
}

.customDropDown.p-tieredmenu .p-menuitem-active>p-tieredmenusub>.p-submenu-list {
	right: 100% !important;
	left: unset !important;
}

.p-dropdown-panel {
	max-width: 350px !important;
}

/*:host {
	::ng-deep {
	}
}*/

.p-tooltip {
	min-width: 300px !important;
	max-width: fit-content !important;
}


.flex-width {
	display: flex;
	flex-flow: row;
	width: 100%;
	overflow: hidden;
	flex-wrap: wrap;
}

.width-flex-0 {
	flex: 0 1 auto;
}

.width-flex-1 {
	flex: 1 1 auto;
}

.white-box {
	background-color: white;
	box-shadow: var(--light-box-shadow);
}

.border-radius-bottom {
	border-bottom-right-radius: 0.5rem !important;
	border-bottom-left-radius: 0.5rem !important;
}

.border-radius {
	border-radius: 0.5rem !important;
}

.preperty-values>div>div {
	display: flex;
}

.small-label h5 {
	font-size: 18px;
	font-weight: bolder;
}

.preperty-values>div>div>div {
	border-top: 1px solid var(--box-border-color);
	border-left: 1px solid var(--box-border-color);
	font-weight: bold;
}

.preperty-values>div>div:first-child>div:first-child {
	border-top-left-radius: 10px;
}

.preperty-values>div>div:first-child>div:nth-child(2) {
	border-top-right-radius: 10px;
}

.preperty-values>div>div:last-child>div:first-child {
	border-bottom-left-radius: 10px;
}

.preperty-values>div>div:last-child>div:nth-child(2) {
	border-bottom-right-radius: 10px;
}

.preperty-values>div>div:last-child>div {
	border-bottom: 1px solid var(--box-border-color);
}

.preperty-values>div>div>div:nth-child(2) {
	width: 40%;
	border-right: 1px solid var(--box-border-color);
	background-color: var(--main-bg-color);
	overflow-wrap: break-word;
}

.preperty-values>div>div>div:first-child {
	width: 60%;
	color: var(--main-grey-color);
}

.preperty-values .centered-values>div>div:nth-child(2) {
	text-align: center;
}

.info-cards>div {
	display: flex;
	flex: 1 1 auto;
	align-items: center;
	flex-direction: column;
}

.info-cards img {
	height: 70px;
}

.info-cards>div:hover {
	color: var(--main-blue-color);
}

.font-weight-normal {
	font-weight: normal !important;
}

.sct-tab .navbar-nav .nav-item .active.analytics-nav-item,
.sct-tab .navbar-nav .nav-item .analytics-nav-item:hover {
	background-color: var(--main-blue-color) !important;
	color: var(--main-white-color) !important;
}

.analytics-nav-item {
	border-radius: 20px !important;
	height: 35px !important;
	padding: 0px 10px !important;
	padding-top: 3px !important;
}

.analytics-nav .sct-tab .nav-tabs {
	padding: 10px 0px;
}

@media (max-width:767px) {
	.mapbox-widget {
		margin-left: -1.5rem;
		margin-right: -1.5rem;
	}
}

.p-calendar-w-btn .p-datepicker-trigger {
	color: black !important;
	background-color: white !important;
	border: 1px solid #ced4da !important;
	border-left: none !important;
}

p-calendar .p-inputtext:focus+.p-datepicker-trigger,
p-calendar .p-inputtext:hover+.p-datepicker-trigger {
	border: 1px solid #2196F3 !important;
	border-left: none !important;
}

p-calendar .p-datepicker-trigger:focus+.p-inputtext,
p-calendar .p-inputtext:focus,
p-calendar .p-datepicker-trigger:hover+.p-inputtext {
	border: 1px solid #2196F3 !important;
	border-right: none !important;
}

p-calendar.ng-dirty.ng-invalid>.p-calendar>.p-datepicker-trigger {
	border: 1px solid #f44336 !important;
	border-left: none !important;
}

bs-datepicker-container,
bs-daterangepicker-container {
	z-index: 1082;
}

.p-calendar-w-btn input {
	border-right: 0;
}

.size-15x15 {
	width: 20px;
	height: 20px;
}

.opacity-50 {
	filter: opacity(0.5);
}

.d-contents {
	display: contents !important;
}

.settings-tab-item-container {
	height: 10rem;
	min-width: 7.5;
	max-width: 100%;
}

.page-cards {
	flex-direction: row !important;
}

.page-cards>li {
	font-size: smaller;
	display: flex;
	flex: 1 1 auto;
	align-items: center !important;
	flex-direction: column;
}

.page-cards>li:not(:first-child) {
	border-left: 2px solid var(--box-border-color) !important;
	border-bottom: 2px solid var(--box-border-color) !important;
}

.page-cards>li:first-child {
	border-bottom: 2px solid var(--box-border-color) !important;
}

.size-35x35 {
	width: 35px;
	height: 35px;
}

.size-25x25 {
	width: 25px;
	height: 25px;
}

.size-20x20 {
	width: 20px;
	height: 20px;
}

.size-15x15 {
	width: 20px;
	height: 20px;
}

.opacity-50 {
	filter: opacity(0.5);
}

.dropdown-menu-right {
	left: unset;
	right: 0px;
}

.showOnPrint {
	display: none;
}

@media print {
	.showOnPrint {
		display: block !important;
	}

	.showOnPrintToggle {
		display: block !important;
	}

	.noprint {
		display: none !important;
	}

	.page-break-inside {
		page-break-inside: avoid;
	}
}


.underline-on-hover {
	text-decoration: none !important;
}

.underline-on-hover:hover {
	text-decoration: underline !important;
}

.small-text {
	font-size: 80%;
	font-weight: 400;
}

.p-blockui-document {
	z-index: 9999999 !important;
}

.p-toast,
.p-toast-center {
	z-index: 9999998 !important;
}


.p-tooltip {
	background-color: transparent !important;
}

.p-tooltip-text {
	color: #004085 !important;
	background-color: #cce5ff !important;
	border-color: #b8daff !important;
	min-width: 300px !important;
}

.input-icon {
	float: right;
	margin-right: 0.5em;
	margin-top: -1.8em;
	position: relative;
	z-index: 2;
	color: black;
	cursor: pointer;
}

.menu-icon {
	font-size: 50px !important;
}

.nav-link.active {
	border-bottom: 3px solid var(--main-blue-color);
	color: var(--main-blue-color);
}

.pointer-cursor {
	cursor: pointer;
}

.opacity-0 {
	opacity: 0;
}